/* @import url('http://fonts.cdnfonts.com/css/helvetica-neue-9'); */
/* https://www.cdnfonts.com/helvetica-neue-9.font */
body {
	margin: 0;
	font-family: 'Helvetica Neue', sans-serif;
	/* font-family: 'Helvetica 25 UltraLight', sans-serif;
	font-family: 'Helvetica 35 Thin', sans-serif;
	font-family: 'Helvetica 45 Light', sans-serif;
	font-family: 'Helvetica 55 Roman', sans-serif;
	font-family: 'Helvetica 65 Medium', sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: rgba(242, 242, 242, 1);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
